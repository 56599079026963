interface Link {
  leakCheck: boolean;
  hbwPwned: boolean;
  unknown: boolean | undefined;
  dehashed: boolean | undefined;
}

export { Link };

export function getRules(t: any, ignoreEmailRule = false) {
  const generalRules = [(value: string) => !!value || t('validation.required'), (value: string) => (value || '').length <= 200 || t('validation.maxChars'), (value: string) => (value || '').length > 3 || t('validation.minChars')]

  const isEmailValid = (value: string) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || t('validation.invalidEmail');
  };

  const isEmailInvalid = (value: string) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !pattern.test(value) || t('validation.invalidNoEmail');
  };

  const isValidDomain = (value: string) => {
    const pattern = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    return pattern.test(value) || t('validation.invalidDomain');
  };

  /* eslint-disable @typescript-eslint/camelcase */
  const ruleTypes = {
    email: ignoreEmailRule ? [] : [isEmailValid],
    mass: [isEmailInvalid],
    login: [isEmailInvalid],
    pass_email: [],
    domain_email: [isEmailInvalid, isValidDomain],
    pass_login: [],
    phone: [isEmailInvalid],
    pass_phone: [],
    mc: [],
    pass_mc: [],
    hash: [isEmailInvalid],
    username: [],
    lastip: [],
    password: [],
    name: [],
  };
  return { generalRules, ruleTypes };
}

function getBlock(t: any) {
  const onlyLeakCheck = {
    Linked: {
      leakCheck: true,
      hbwPwned: false
    },
    value: 'leakCheck',
    Name: t('ref.leakCheck')
  };

  const onlyHBPW = {
    Linked: {
      leakCheck: false,
      hbwPwned: true
    },
    value: 'hibp',
    Name: t('ref.hibp')
  };
  const bothLeaks = {
    Linked: {
      leakCheck: true,
      hbwPwned: true
    },
    value: 'both',
    Name: t('ref.hibpLeak')
  };

  const unknownLeaks = {
    Linked: {
      unknown: true
    },
    value: 'unknown',
    Name: t('ref.unknown')
  };

  return { onlyLeakCheck, onlyHBPW, bothLeaks, unknownLeaks };
}

export function getReferences(t: any) {
  const { onlyLeakCheck, onlyHBPW, bothLeaks, unknownLeaks } = getBlock(t);
  return [onlyLeakCheck, onlyHBPW, bothLeaks, unknownLeaks];
}

export function getTypes(t: any) {
  const { onlyLeakCheck, onlyHBPW, bothLeaks } = getBlock(t);
  return [
    {
      name: t('types.email'),
      value: 'email',
      references: [bothLeaks]
    },
    {
      name: t('types.mass'),
      value: 'mass',
      references: [onlyLeakCheck]
    },
    {
      name: t('types.login'),
      value: 'login',
      references: [bothLeaks]
    },
    {
      name: t('types.pass_email'),
      value: 'pass_email',
      references: [onlyLeakCheck]
    },
    {
      name: t('types.domain_email'),
      value: 'domain_email',
      references: [onlyLeakCheck]
    },
    {
      name: t('types.pass_login'),
      value: 'pass_login',
      references: [onlyLeakCheck]
    },
    {
      name: t('types.phone'),
      value: 'phone',
      references: [onlyLeakCheck]
    },

    {
      name: t('types.pass_phone'),
      value: 'pass_phone',
      references: [onlyLeakCheck]
    },
    {
      name: t('types.mc'),
      value: 'mc',
      references: [onlyLeakCheck]
    },
    {
      name: t('types.pass_mc'),
      value: 'pass_mc',
      references: [onlyLeakCheck]
    },
    {
      name: t('types.hash'),
      value: 'hash',
      references: [onlyLeakCheck]
    }
  ];
}

export function getTypesDehashed(t: any) {
  return [
    {
      name: t('types.free'),
      value: 'free'
    },
    {
      name: t('types.email'),
      value: 'email'
    },
    {
      name: t('types.login'),
      value: 'username'
    },
    {
      name: 'Password',
      value: 'password'
    },
    {
      name: 'Hashed Password',
      value: 'hashed_password'
    },
    {
      name: 'IP',
      value: 'ip_address'
    },
    {
      name: 'Name',
      value: 'name'
    },
    {
      name: 'Address',
      value: 'address'
    },
    {
      name: 'Phone',
      value: 'phone'
    },
    {
      name: 'VIN',
      value: 'vin'
    },
    {
      name: 'Domain',
      value: 'domain'
    }
  ];
}

export function getRulesDehashed(t: any) {
  const generalRules = [(value: string) => !!value || t('validation.required'), (value: string) => (value || '').length <= 200 || t('validation.maxChars'), (value: string) => (value || '').length > 3 || t('validation.minChars')]

  const isEmailValid = (value: string) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || t('validation.invalidEmail');
  };

  const isEmailInvalid = (value: string) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !pattern.test(value) || t('validation.invalidNoEmail');
  };

  const isValidDomain = (value: string) => {
    const pattern = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    return pattern.test(value) || t('validation.invalidDomain');
  };

  /* eslint-disable @typescript-eslint/camelcase */
  const ruleTypes = {
    free: [],
    email: [],
    password: [],
    username: [isEmailInvalid],
    ip_address: [isEmailInvalid],
    name: [isEmailInvalid],
    address: [isEmailInvalid],
    phone: [isEmailInvalid],
    vin: [isEmailInvalid],
    domain: [isValidDomain]
  };
  return { generalRules, ruleTypes };
}
